<template>
  <div class="editCon">
    <el-drawer
      title="编辑广告"
      :visible="editAdsShow"
      direction="rtl"
      @close="close"
      wrapperClosable
      size="1250px"
      :append-to-body="true"
    >
      <div
        class="drawerCon"
        v-loading="loading"
      >
        <div class="editAdsCon">
          <!-- {{ adsInfo }} -->
          <ads
            adType="edit"
            :accountId="accountId"
            :initData="initData"
            @setAds="setAds"
            :edit="true"
            :adsInfo="adsInfo"
            :pixelList="pixelList"
            :creat="creat"
            :campaign_objective="campaign_objective"
            :rowThirdNum="rowThirdNum"
            ref="ads"
          ></ads>
          <div
            class="cover"
            v-if="preview"
          ></div>
        </div>
      </div>

      <div
        class="demo-drawer__footer"
        v-if="!preview"
      >
        <p></p>
        <div>
          <el-button
            type="primary"
            @click="close"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="sure"
            id="editAdEvent"
            >保存</el-button
          >
        </div>
        <p></p>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import ads from './ads';
import { adForUpdate, updateAd, accountPixel } from '@/api/creatAd.js';
import { adForCreate, createAd } from '@/api/adManagement.js';
import { promoteableSale } from '@/api/creatAd.js';
import tools from './tools';
export default {
  props: ['editAdsShow', 'initData', 'accountId', 'id', 'preview', 'type', 'adScope', 'creat'],
  mixins: [tools],
  data() {
    return {
      adsInfo: null,
      ads: [],
      pixelList: [],
      draftCampaignId: 0,
      loading: false,
      creatInfo: null,
      campaignId: null,
      campaign_objective: null,
      adsetId: null,
    };
  },
  computed: {
    account() {
      if (this.adScope && this.adScope.row.rowAccountId) {
        return this.adScope.row.rowAccountId;
      } else {
        return this.accountId;
      }
    },
    rowThirdNum() {
      console.log(this.adScope);
      if (this.adScope && this.adScope.row.rowThirdNum) {
        return this.adScope.row.rowThirdNum;
      } else {
        return null;
      }
    },
  },
  components: {
    ads,
  },
  methods: {
    // 关闭弹窗
    close() {
      let str = 'creatAdsShow';
      if (this.creat) {
        str = 'creatAdsShow';
      } else {
        str = 'editAdsShow';
      }
      this.$refs.ads.cancleVideoUpload ? this.$refs.ads.cancleVideoUpload() : null;
      this.$emit('close', str);
    },
    setAds(v) {
      this.ads = v;
      // this.adsInfo = v
    },
    saveEditAds() {
      let info = JSON.parse(JSON.stringify(this.ads[0]));
      let flagMsg = { flag: true, msg: '' };
      // if(info.media_type == 'muti'){
      //     info.title = '';
      //     info.content = '';
      //     let list = JSON.parse(JSON.stringify(info.mutiImgs.slice(0,info.mutiImgs.length-1)))
      //     if(list.length<3){
      //         this.$message({
      //             type:'warning',
      //             message:'请至少添加三张轮播'
      //         })
      //         flag = false;
      //     }
      //     if(!list.every(v=>v.image&&v.link_url)){
      //         this.$message({
      //             type:'warning',
      //             message:'请完善轮播信息'
      //         })
      //         flag = false;
      //     }else{
      //         info.mutiImgs = list;
      //     }
      // }else if(info.media_type == 'post'){
      //     if(!info.post_id){
      //         this.$message({
      //             type:'warning',
      //             message:'请完善帖子信息'
      //         })
      //         flag = false;
      //     }
      // }else if(info.media_type == 'image' || info.media_type == 'video'){
      //     // if(!info.conversion_domain){
      //     //     this.$message({
      //     //         type:'warning',
      //     //         message:'请输入正确的网域'
      //     //     })
      //     //     flag = false;
      //     // }
      //     if(!info.image || !info.message){
      //         this.$message({
      //             type:'warning',
      //             message:'请完善单图/视频信息'
      //         })
      //         flag = false;
      //     }
      // }else if(info.media_type == 'asset_feed'){
      //     let fbAssetFeedSpec = info.fbAssetFeedSpec || {};
      //     fbAssetFeedSpec.actionTypes = fbAssetFeedSpec.actionTypes.filter(v=>v);
      //     fbAssetFeedSpec.linkUrls = fbAssetFeedSpec.linkUrls.filter(v=>v);
      //     let imgList = fbAssetFeedSpec.imgList || []
      //     let videos = fbAssetFeedSpec.videos || [];
      //     let list = imgList.concat(videos);
      //     if((!fbAssetFeedSpec.mAdFormats.length) || (!list.length ) || (!fbAssetFeedSpec.titles.length) || (!fbAssetFeedSpec.mDescriptions.length) || (!fbAssetFeedSpec.mBodies.length) || (!fbAssetFeedSpec.actionTypes.length) || (!fbAssetFeedSpec.linkUrls.length)){
      //         this.$message({
      //             type:'warning',
      //             message:'请完善动态广告信息'
      //         })
      //         flag = false;
      //     }
      //     flag = this.previewBefore(fbAssetFeedSpec)
      // }
      flagMsg = this.validCreative(info, { objective: this.campaign_objective });
      console.log(flagMsg.flag, 'flag');

      if (!flagMsg.flag) {
        if (flagMsg.msg) {
          this.$message({
            message: flagMsg.msg,
            type: 'warning',
          });
        }

        return false;
      }
      info = { ...this.validCreative(info, { objective: this.campaign_objective }).creative, ...info.copywriter };
      let params = {
        level: 'ad',
        type: this.type,
        adcreative: info,
        adsetId: this.adsetId,
      };
      if (this.type == 'DRAFT') {
        params.draftCampaignId = this.draftCampaignId;
        params.draftId = this.id;
      } else {
        params.accountId = this.account;
        params.adId = this.id;
        params.campaignId = this.campaignId;
      }
      params.conversion_domain = info.conversion_domain;
      params.fbPixelId = this.adsInfo?.fbPixelId;
      // params.adcreative.mediaSourceType = params.adcreative.sourceType;
      delete params.adcreative.conversion_domain;
      this.$showLoading();
      updateAd(params, this.rowThirdNum)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.$emit('get-list', this.adScope);
            this.close();
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    saveCreatAds() {
      let flag = this.validCreative(this.ads[0], { objective: this.campaign_objective }).flag;
      console.log(flag, 'flag');

      if (!flag) return false;
      let params = {
        ...this.creatInfo,
        status: this.creatInfo.status.key,
        adcreative: { ...this.validCreative(this.ads[0], { objective: this.campaign_objective }).creative },
        objective: this.campaign_objective,
      };
      delete params.userId;
      delete params.name;
      delete params.adcreative.conversion_domain;
      this.$showLoading();
      createAd({ ...params }, this.rowThirdNum).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '创建成功',
          });
          this.$emit('get-list', this.adScope);
          this.close();
        }
      });
    },
    // 点击保存
    sure() {
      if (this.creat) {
        this.saveCreatAds();
        // console.log(this.ads[0]);
      } else {
        this.saveEditAds();
      }
    },
    // 编辑的时候获取广告信息
    async getInitData() {
      // console.log(this.id);
      let params = {
        level: 'ad',
        type: this.type,
      };
      if (this.type == 'DRAFT') {
        params.draftId = this.id;
      } else {
        params.accountId = this.account;
        params.fbId = this.id;
      }
      this.loading = true;
      await adForUpdate(params, this.rowThirdNum).then(async (res) => {
        this.loading = false;
        if (res.code == 0) {
          this.draftCampaignId = res.data.draftCampaignId;
          this.adsetId = res.data.adsetId;
          this.adsInfo = res.data.adcreative;
          if (this.adsInfo.saleId) {
            const { langId, productId } = await this.getPromoteableSale(this.adsInfo.saleId);
            this.adsInfo.langId = langId;
            this.adsInfo.productId = productId;
          }
          this.adsInfo.promoteUrl = res.data.promoteUrl;
          this.adsInfo.fbPixelId = res.data.fbPixelId;
          this.adsInfo.videoStatus = 'ready';
          this.adsInfo.conversion_domain = res.data.conversion_domain;
          this.campaignId = res.data.campaignId;
          this.campaign_objective = res.data.campaign_objective;
          if (this.adsInfo.media_type != 'muti') {
            this.adsInfo.mutiImgs = [
              { name: '', fixed: false, copywriter: { title: '', content: '' } },
              { name: '', fixed: false, copywriter: { title: '', content: '' } },
              { name: '', fixed: false, copywriter: { title: '', content: '' } },
            ];
          } else if (this.adsInfo.media_type == 'asset_feed') {
            let info = {};
            info.mAdFormats = info.mAdFormats || [];
            info.imgList = info.imgList || [];
            info.videos = info.videos || [];
            info.titles = info.titles || [];
            info.mDescriptions = info.mDescriptions || [];
            info.mBodies = info.mBodies || [];
            info.actionTypes = info.actionTypes || [];
            info.linkUrls = info.linkUrls || [];
            this.adsInfo.fbAssetFeedSpec = { ...this.adsInfo.fbAssetFeedSpec, ...info };
          } else {
            this.adsInfo.mutiImgs.push({ name: '', fixed: false, copywriter: { title: '', content: '' } });
          }
        }
        console.log(this.adsInfo);
      });
    },
    // 创建的时候的广告组信息
    getInit() {
      let params = {
        adsetId: this.id,
      };
      this.loading = true;
      adForCreate(params, this.rowThirdNum).then((res) => {
        this.loading = false;
        // console.log(res);
        this.campaign_objective = res.data.objective.key;
        this.adsInfo = {
          web_url: '',
          copywriter: { title: '', content: '' },
          media_type: 'image',
          image: '',
          message: '',
          sk: 'timeline',
          action_type: this.initData.ad ? this.initData.ad.CallToActionType[0].key : null,
          mutiImgs: [
            { name: '', fixed: false, copywriter: { title: '', content: '' }, link_url: '' },
            { name: '', fixed: false, copywriter: { title: '', content: '' }, link_url: '' },
            { name: '', fixed: false, copywriter: { title: '', content: '', link_url: '' } },
          ],
          fbAssetFeedSpec: {
            titles: [''],
            mDescriptions: [''],
            mBodies: [''],
            imgList: [],
            videos: [],
            mAdFormats: ['AUTOMATIC_FORMAT'],
            actionTypes: [this.initData.ad ? this.initData.ad.CallToActionType[0].key : ''],
            linkUrls: [],
          },
        };
        this.$set(this.adsInfo, 'promoteUrl', res.data.tracingTpl);
        this.$set(this.adsInfo, 'fbPixelId', res.data.fbPixelId);
        // console.log(this.adsInfo);
        this.creatInfo = res.data;
      });
    },
    async getPromoteableSale(sale_id) {
      console.log('getPromoteableSale', sale_id);
      if (!sale_id) return;
      this.$showLoading();
      const res = await promoteableSale({ sale_id });
      this.$hideLoading();
      return {
        langId: res?.data?.sale?.langId,
        productId: res?.data?.sale?.productId,
      };
    },
  },
  watch: {
    editAdsShow() {
      if (this.editAdsShow) {
        accountPixel({ accountId: this.account }, this).then((res) => {
          // console.log(res);
          this.pixelList = res.data;
        });
        if (this.creat) {
          this.getInit();
        } else {
          this.getInitData();
        }
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.editAdsCon {
  padding-bottom: 50px;

  .el-form-item {
    .el-tabs__header {
      display: block;
      margin-bottom: 0 !important;
    }
    .el-tabs__content {
      padding: 16px !important;
    }
  }
}
.campainCon {
  padding: 0 20px;
}
</style>
